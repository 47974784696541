import { useUserStore } from '../store/user'

export default defineNuxtRouteMiddleware(async () => {
  /**
   * we have to fetch user here instead of using the user from the context
   * as for the first entry user is nor fetched yet
   * and this middleware always returns false
   */
  const { getUser } = useUserStore()
  const res = await getUser()
  if (!res.data.is_admin) {
    return navigateTo('/login')
  }
})
